.iris-link-primary-desktop {
  @include iris-link(
    $isMobile: false,
    $isQuiet: false
  );
}

.iris-link-primary-mobile {
  @include iris-link(
    $isMobile: true,
    $isQuiet: false
  );
}

.iris-link-quiet-desktop {
  @include iris-link(
    $isMobile: false,
    $isQuiet: true
  );
}

.iris-link-quiet-mobile {
  @include iris-link(
    $isMobile: true,
    $isQuiet: true
  );
}

.iris-link-primary-responsive {
  @include iris-link-responsive(
    $isQuiet: false
  );
}

.iris-link-quiet-responsive {
  @include iris-link-responsive(
    $isQuiet: true
  );
}

div.mat-mdc-select-panel {
  background-color: $color-neutral-white !important;
  padding: 0 !important;
}

.mat-mdc-option {
  background-color: $color-neutral-white;
  &:hover:not(.mdc-list-item--disabled) {
    background-color: rgba(0, 0, 0, .04) !important;
  }
  &:focus:not(.mat-mdc-option-disabled) {
    background-color: rgba(0, 0, 0, .12) !important;
  }

  &.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background-color: rgba(0, 0, 0, .04) !important;
  }
}

.cdk-overlay-pane {
  &:has(.mat-mdc-select-panel) {
    transform: translateX(-20px) translateY(-33px);
  }
  
  &.mat-mdc-select-panel-above,
  &.mat-mdc-select-panel-below {
    transform: translateX(-20px) translateY(40px);
  }
  
  .mat-mdc-select-panel {
    min-width: 100%;
    max-width: 100%;
  }

  .mat-mdc-option {
    color: #002532 !important;
    font: 400 14px proxima-nova !important;

    .mat-mdc-option-active {
      .mdc-list-item {
        color: #002532 !important;
      }
    }
  }
}

.mat-mdc-autocomplete-panel {
  background-color: $color-neutral-white !important;
}

.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: rgba(0, 0, 0, .04) !important;
}

.mat-pseudo-checkbox-minimal {
  display: none !important;
}

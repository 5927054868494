@use '@angular/material' as mat;
@use 'material-mdc-mixins' as material-mdc-mixins;

@import '1-tokens/-index';

@import 'theme';
@import 'formlib.theme';
@import 'sondermind';
@import './styles/sonder-modal';

// Imports from iris-design-system-styles package
@import 'typography.scss';
@import 'link.scss';

@import url('https://use.typekit.net/bnw6lfh.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// include legacy-core to suit legacy components, stalling on mdc migration
@include mat.legacy-core();

@include material-mdc-mixins.all-component-themes($color-config-sm-app, $typography-config-sm-body);

@include mat.all-legacy-component-typographies();

@include mat-overrides-theme();

.sondermind-design-system,
.sondermind-design-system-inline {
    @include install-sondermind-theme();
}

.sondermind-design-system {
    @include install-sondermind-theme-defaults();
}

@include formlib-theme($color-config-sm-dark);

body {
    margin:  0;
    padding: 0;

    h1,h2,h3,h4,h5,h6 {
        color: inherit;
    }
}

.mat-drawer-container {
    overflow-y: auto !important;

    &, & > .mat-drawer {
        color: inherit;
        background: inherit;
    }
}

body, .sonder-formlib-light-background {
    color: mat.get-color-from-palette(map-get($color-config-sm-app, primary), 'darker');
    background: #fff;
}

.sonder-formlib-dark-background {
  color:      #eee;
  background: $color-brand-1-base;
}

.stickyfill {
  position: sticky;
  top: 0;
}

.warning-message {
    color: mat.get-color-from-palette($sm-app-warn, 'default');
}

.-bg-warn {
  .sonder-form-field-standin {
    background-color: #FF6347 !important;
    color: #FFF !important;
  }

  .sonder-form-field-helptext {
    background-color: #FF6347 !important;
    color: #FFF !important;
    margin-bottom: 0 !important;
    margin-top: -4px !important;
    padding: 3px 20px !important;
    text-align: justify !important;
    border-radius: 0px 0px 6px 6px !important;
  }
}

/* Fix for spinner animations in Angular Material */
.mat-spinner,
.mat-mdc-progress-spinner {
  display: block;
  
  /* Ensure colors are properly applied */
  &.mat-primary {
    --mdc-circular-progress-active-indicator-color: #004455;
  }

  /* Ensure visibility and proper sizing */
  .mdc-circular-progress {
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: visible;
  }
}

/* Force mat-spinner to show animation */
.mat-mdc-progress-spinner.mat-mdc-progress-spinner-indeterminate .mdc-circular-progress__indeterminate-container,
.mat-spinner .mdc-circular-progress__indeterminate-container {
  animation: mdc-circular-progress-rotate 2s linear infinite;
}

.mat-mdc-progress-spinner.mat-mdc-progress-spinner-indeterminate .mdc-circular-progress__spinner-layer,
.mat-spinner .mdc-circular-progress__spinner-layer {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mat-mdc-progress-spinner.mat-mdc-progress-spinner-indeterminate .mdc-circular-progress__indeterminate-circle-graphic,
.mat-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  animation: mdc-circular-progress-spinner-stroke-rotate 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
  stroke-dasharray: 80, 200;
  stroke-dashoffset: 0px;
}

@keyframes mdc-circular-progress-rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes mdc-circular-progress-spinner-layer-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@keyframes mdc-circular-progress-spinner-stroke-rotate {
  0% {
    transform: rotate(0);
  }
  12.5% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(540deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(810deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
$font-size-base: 16px;
$border-radius-00: 0;
$border-radius-01: 0.125rem;
$border-radius-02: 0.25rem;
$border-radius-03: 0.5rem;
$border-radius-04: 1rem;
$border-radius-05: 1.5rem;
$border-radius-full: 50%;
$breakpoint-lg: 1919px;
$breakpoint-md: 1439px;
$breakpoint-sm: 959px;
$breakpoint-xs: 599px;
$color-accessibility-focus-default: #009ea8;
$color-action-active: #0A4E6F;
$color-action-base: #0D6A97;
$color-action-hover: #2696C5;
$color-action-tint: #f9fafd;
$color-brand-1-active: #003242;
$color-brand-1-base: #004455;
$color-brand-1-highlight: #dae3e6;
$color-brand-1-hover: #255564;
$color-brand-1-tint: #f2f5f6;
$color-brand-2-active: #f67748;
$color-brand-2-base: #f99f7e;
$color-brand-2-hover: #fab297;
$color-brand-2-tint: #fff5f2;
$color-dec-blue-10: #d6e4ef;
$color-dec-blue-100: #326eb1;
$color-dec-cyan-10: #d0eef3;
$color-dec-cyan-100: #23accb;
$color-dec-green-10: #e0ecd9;
$color-dec-green-100: #4ba039;
$color-dec-indigo-10: #e7e9f4;
$color-dec-indigo-100: #818ece;
$color-dec-kelly-10: #c8f0e8;
$color-dec-kelly-100: #0fb689;
$color-dec-orange-10: #fae1cd;
$color-dec-orange-100: #eb561d;
$color-dec-purple-10: #f0e9f4;
$color-dec-purple-100: #ba8ecc;
$color-dec-red-10: #fadddb;
$color-dec-red-100: #e9453e;
$color-dec-turquoise-03: #f2fafc;
$color-dec-turquoise-05: #e5f0f1;
$color-dec-turquoise-10: #d7e9eb;
$color-dec-turquoise-100: #005d66;
$color-dec-turquoise-20: #bad6de;
$color-dec-turquoise-30: #98c4cb;
$color-dec-turquoise-40: #6ba8ad;
$color-dec-turquoise-60: #007a84;
$color-dec-turquoise-80: #007a85;
$color-dec-yellow-10: #faf2d4;
$color-dec-yellow-100: #eec331;
$color-message-error-base: #b93646;
$color-message-error-hover: #fceded;
$color-message-error-tint: #fef9f9;
$color-message-success-base: #048106;
$color-message-success-hover: #006900;
$color-message-success-tint: #f8fbf7;
$color-message-warning-base: #fcbf38;
$color-message-warning-hover: #e2b027;
$color-message-warning-tint: #fffdf9;
$color-neutral-02: #fbfbfb;
$color-neutral-03: #f6f7f7;
$color-neutral-05: #f2f2f2;
$color-neutral-15: #d8d8d8;
$color-neutral-30: #b2b2b2;
$color-neutral-40: #90969a;
$color-neutral-60: #5e666b;
$color-neutral-80: #2f3a40;
$color-neutral-default: #001219;
$color-neutral-white: #ffffff;
$elevation-01: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12),
  0px 1px 3px rgba(0, 0, 0, 0.2);
$elevation-04: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12),
  0px 1px 10px rgba(0, 0, 0, 0.2);
$elevation-08: 0px 8px 10px rgba(0, 0, 0, 0.14),
  0px 3px 14px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.2);
$font-family-fallback: Arial;
$font-family-proxima-nova: proxima-nova;
$font-letter-spacing-00: normal;
$font-letter-spacing-01: 0.005em;
$font-letter-spacing-02: 0.01em;
$font-letter-spacing-03: 0.0125em;
$font-letter-spacing-04: 0.0325em;
$font-line-height-01: 0.875rem;
$font-line-height-02: 1rem;
$font-line-height-03: 1.125rem;
$font-line-height-04: 1.25rem;
$font-line-height-05: 1.375rem;
$font-line-height-06: 1.5rem;
$font-line-height-07: 1.75rem;
$font-line-height-08: 2rem;
$font-line-height-09: 2.25rem;
$font-line-height-10: 3.5rem;
$font-size-01: 0.625rem;
$font-size-02: 0.75rem;
$font-size-03: 0.875rem;
$font-size-04: 1rem;
$font-size-05: 1.125rem;
$font-size-06: 1.25rem;
$font-size-07: 1.5rem;
$font-size-08: 1.75rem;
$font-size-09: 2.25rem;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$media-query-expression-all: all;
$media-query-expression-landscape: '(orientation: landscape)';
$media-query-expression-portrait: '(orientation: portrait)';
$media-query-expression-print: print;
$media-query-expression-screen: screen;
$media-query-expression-speech: speech;
$media-query-unit-intervals-em: 0.01;
$media-query-unit-intervals-px: 1;
$media-query-unit-intervals-rem: 0.1;
$spacing-00: 0px;
$spacing-01: 0.25rem;
$spacing-02: 0.5rem;
$spacing-03: 1rem;
$spacing-04: 1.5rem;
$spacing-05: 2rem;
$spacing-06: 2.5rem;
$spacing-07: 3rem;
$spacing-08: 3.5rem;
$tokens: (
  'border-radius': (
    '00': $border-radius-00,
    '01': $border-radius-01,
    '02': $border-radius-02,
    '03': $border-radius-03,
    '04': $border-radius-04,
    '05': $border-radius-05,
    'full': $border-radius-full,
  ),
  'breakpoint': (
    'lg': $breakpoint-lg,
    'md': $breakpoint-md,
    'sm': $breakpoint-sm,
    'xs': $breakpoint-xs,
  ),
  'color': (
    'accessibility': (
      'focus': (
        'default': $color-accessibility-focus-default,
      ),
    ),
    'action': (
      'active': $color-action-active,
      'base': $color-action-base,
      'hover': $color-action-hover,
      'tint': $color-action-tint,
    ),
    'brand': (
      '1': (
        'active': $color-brand-1-active,
        'base': $color-brand-1-base,
        'highlight': $color-brand-1-highlight,
        'hover': $color-brand-1-hover,
        'tint': $color-brand-1-tint,
      ),
      '2': (
        'active': $color-brand-2-active,
        'base': $color-brand-2-base,
        'hover': $color-brand-2-hover,
        'tint': $color-brand-2-tint,
      ),
    ),
    'dec': (
      'blue': (
        '10': $color-dec-blue-10,
        '100': $color-dec-blue-100,
      ),
      'cyan': (
        '10': $color-dec-cyan-10,
        '100': $color-dec-cyan-100,
      ),
      'green': (
        '10': $color-dec-green-10,
        '100': $color-dec-green-100,
      ),
      'indigo': (
        '10': $color-dec-indigo-10,
        '100': $color-dec-indigo-100,
      ),
      'kelly': (
        '10': $color-dec-kelly-10,
        '100': $color-dec-kelly-100,
      ),
      'orange': (
        '10': $color-dec-orange-10,
        '100': $color-dec-orange-100,
      ),
      'purple': (
        '10': $color-dec-purple-10,
        '100': $color-dec-purple-100,
      ),
      'red': (
        '10': $color-dec-red-10,
        '100': $color-dec-red-100,
      ),
      'turquoise': (
        '10': $color-dec-turquoise-10,
        '20': $color-dec-turquoise-20,
        '30': $color-dec-turquoise-30,
        '40': $color-dec-turquoise-40,
        '60': $color-dec-turquoise-60,
        '80': $color-dec-turquoise-80,
        '100': $color-dec-turquoise-100,
        '03': $color-dec-turquoise-03,
        '05': $color-dec-turquoise-05,
      ),
      'yellow': (
        '10': $color-dec-yellow-10,
        '100': $color-dec-yellow-100,
      ),
    ),
    'message': (
      'error': (
        'base': $color-message-error-base,
        'hover': $color-message-error-hover,
        'tint': $color-message-error-tint,
      ),
      'success': (
        'base': $color-message-success-base,
        'hover': $color-message-success-hover,
        'tint': $color-message-success-tint,
      ),
      'warning': (
        'base': $color-message-warning-base,
        'hover': $color-message-warning-hover,
        'tint': $color-message-warning-tint,
      ),
    ),
    'neutral': (
      '15': $color-neutral-15,
      '30': $color-neutral-30,
      '40': $color-neutral-40,
      '60': $color-neutral-60,
      '80': $color-neutral-80,
      '02': $color-neutral-02,
      '03': $color-neutral-03,
      '05': $color-neutral-05,
      'default': $color-neutral-default,
      'white': $color-neutral-white,
    ),
  ),
  'elevation': (
    '01': $elevation-01,
    '04': $elevation-04,
    '08': $elevation-08,
  ),
  'font': (
    'family': (
      'fallback': $font-family-fallback,
      'proxima-nova': $font-family-proxima-nova,
    ),
    'letter-spacing': (
      '00': $font-letter-spacing-00,
      '01': $font-letter-spacing-01,
      '02': $font-letter-spacing-02,
      '03': $font-letter-spacing-03,
      '04': $font-letter-spacing-04,
    ),
    'line-height': (
      '10': $font-line-height-10,
      '01': $font-line-height-01,
      '02': $font-line-height-02,
      '03': $font-line-height-03,
      '04': $font-line-height-04,
      '05': $font-line-height-05,
      '06': $font-line-height-06,
      '07': $font-line-height-07,
      '08': $font-line-height-08,
      '09': $font-line-height-09,
    ),
    'size': (
      '01': $font-size-01,
      '02': $font-size-02,
      '03': $font-size-03,
      '04': $font-size-04,
      '05': $font-size-05,
      '06': $font-size-06,
      '07': $font-size-07,
      '08': $font-size-08,
      '09': $font-size-09,
    ),
    'weight': (
      'bold': $font-weight-bold,
      'medium': $font-weight-medium,
      'normal': $font-weight-normal,
      'semibold': $font-weight-semibold,
    ),
  ),
  'media-query': (
    'expression': (
      'all': $media-query-expression-all,
      'landscape': $media-query-expression-landscape,
      'portrait': $media-query-expression-portrait,
      'print': $media-query-expression-print,
      'screen': $media-query-expression-screen,
      'speech': $media-query-expression-speech,
    ),
    'unit-intervals': (
      'em': $media-query-unit-intervals-em,
      'px': $media-query-unit-intervals-px,
      'rem': $media-query-unit-intervals-rem,
    ),
  ),
  'spacing': (
    '00': $spacing-00,
    '01': $spacing-01,
    '02': $spacing-02,
    '03': $spacing-03,
    '04': $spacing-04,
    '05': $spacing-05,
    '06': $spacing-06,
    '07': $spacing-07,
    '08': $spacing-08,
  ),
);
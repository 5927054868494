@use '@angular/material' as mat;

@import '1-tokens/-index'; // design tokens
@import '2-tools/_tools.helpers';
@import '2-tools/_tools.breakpoints';
@import '2-tools/_tools.typography.scss'; // typography mixins
@import './styles/progressbar';
@import 'sondermind';

@mixin disabledFade() {
    opacity: 1;
    transition: opacity 250ms ease;
    &.sm-disabled {
        opacity: 0.35;
    }
}

// todo: right now this is dependent on _helpers from flows-wizard
@mixin formlib-theme($color-config) {
    $primary: map-get($color-config, primary);
    $accent:  map-get($color-config, accent);

    .sonder-progressbar {
        @include progressbar-element($color-config);
    }

    .sonder-form-legend {
      @include disabledFade();

      &:not(.-inline) {
        padding-bottom: 35px;
      }

      .-title {
        @include sm-font-legend();
      }

      .-subtitles {
        @include sm-font-medium();
        margin-top: 10px;

        ul, ol {
          padding-left: 1em;
        }
      }
    }

    .mat-icon {
        &.mat-icon-inline {
            position: relative;
        }

        &.mat-white {
            color: white;
        }
    }

    .sonder-overlay-backdrop-verydark {
        background: rgba(0,0,0,0.9);
    }

    sonder-card-upload-card-capture .-viewfinder {
        border-color: mat.get-color-from-palette($primary, 'darker');
    }

    .sonder-form-button-container {
        padding-right: $spacing-05;
        margin-top: $spacing-03;

        @include iris-media("<=xs") {
          padding-right: 0;
        }

        &:after {
            content: '';
            display: table;
            clear: both;
        }

        .-secondary {
            float: right;
        }

        .spinner-button {
            padding: 9px 6px;
        }

        &.mq-gt-sm {
            > * {
                margin-right: 16px;
                &:last-child {
                    margin-right: 0;
                }

                &.-secondary {
                    margin-right: 0;
                    margin-left: 16px;
                }
            }
        }

        &.mq-lt-sm {
            > * {
                margin-right: 16px;

                &:last-child {
                    margin-right: 0;
                }

                &.-secondary {
                    margin-right: 0;
                    margin-left: 16px;
                }
            }
        }
    }

    .sonder-external-link {
      color: mat.get-color-from-palette($primary, 500);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }

      .sonder-formlib-dark-background & {
        color: mat.get-color-from-palette($primary, 200);
      }
    }

    .sonder-link-button,
    .mat-button.sonder-link-button,
    .mat-button.sonder-back-button
    {
        @include sm-font-small();
        // letter-spacing: 2px;

        color: inherit;
        text-decoration: none;

        cursor: pointer;

        line-height: 36px;
        padding: 3px 15px;
        margin: 6px 0;

        border-radius: 5px;
        transition: background-color 150ms ease;

        .sonder-formlib-dark-background & {
            background: rgba(255, 255, 255, 0);
            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }
        }
    }

    .sonder-mini-button,
    .mat-button.sonder-mini-button,
    .mat-flat-button.sonder-mini-button
    {
        padding: 1px 10px 0;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .sonder-next-button, .sonder-back-button
    .mat-button.sonder-next-button, .mat-button.sonder-back-button
    {
      @include sm-font-label();

      color: inherit;
      text-decoration: none;

      line-height: 36px;

      display: inline-block;
      padding: 3px 15px;
      margin: -3px -15px;

      .mat-icon {
        position: relative;
        top: 6px;

        transform: translateX(0);
        transition: transform 250ms ease;
      }

      &:hover {
        // override from external link
        text-decoration: none;
      }

      &.sonder-back-button {
        &:hover {
          .mat-icon {
            transform: translateX(-5px);
          }
        }
      }

      &.sonder-next-button {
        &:hover {
          .mat-icon {
            transform: translateX(5px);
          }
        }
      }
    }

    .sonder-popup-dialog {
        background: #f6fbfb;
        color: mat.get-color-from-palette($primary, 'default');

        box-sizing: border-box;
        max-width: 375px;
        padding: 30px;

        ul {
            margin: 0;
        }

        @include sm-font-small();

        box-shadow: 0 64px 64px 0 rgba(0, 0, 0, 0.05), 0 32px 32px 0 rgba(0, 0, 0, 0.05), 0 16px 16px 0 rgba(0, 0, 0, 0.05), 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 4px 4px 0 rgba(0, 0, 0, 0.05);
        border-radius: 4px;
    }

    hr.sonder-form-separator {
        flex: 1 1 100%;
        border: none;
        border-top: 1px solid rgba(mat.get-color-from-palette($primary, 'darker'), 0.25);
    }

    .sonder-form-standalone {
        margin-top: 30px; // make up for the spacing of labels
    }

    .sonder-form-field {
        display: block;
        color: inherit;

        position: relative;
        box-sizing: border-box;

        .sonder-form-field-content, .sonder-form-field-standin {
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: stretch;
            justify-content: space-between;

            // after content is relative to the content, not the label or helptext
            position: relative;

            z-index: 1;

            width: 100%;
            box-sizing: border-box;

            border-radius: 5px;

            background: $color-neutral-05;
            color: mat.get-color-from-palette($primary, 'default');

            line-height: 1.7;
            font-size: $font-size-05;
            font-weight: $font-weight-medium;
            font-family: $font-family-proxima-nova;

            border: 2px solid transparent;
            border-radius: 5px;
            background-clip: content-box;

            // individual children types:
            > input, > textarea, > mat-select {

                flex: 1 auto;
                width: 1px; // should grow via flex

                // ie11 doesn't like line-height
                height: 50px;

                font: inherit;
                color: inherit;

                box-sizing: border-box;
                padding: 10px 20px;
                margin: 0;

                background: none;
                border: none;

                outline: none;

                .mat-mdc-select-value {
                    color: inherit;
                }

                &:not(.placeholder)::placeholder, .mat-mdc-select-placeholder {
                    color: inherit;
                    font-weight: 400;
                }
            }

            // don't change textarea heights
            > textarea {
                height: auto;
            }

            // datepicker toggle is visible, this just takes unwanted room in the flex
            > .mat-datepicker {
                display: none;
            }

            // mat select is specialized by not being mat-focusable
            // we have to manage and display our own focus/error ring
            > .mat-mdc-select {
                border: 1px solid transparent;
                border-radius: 5px;
            }

            > [matsuffix] {
                flex: 0 0 auto;
                order: 1;

                color: mat.get-color-from-palette($primary, 'default');

                margin-right: 8px;

                &.mat-icon-button {
                    padding: 0 8px;
                }
            }

            > [matprefix] {
                flex: 0 0 auto;
                order: -1;

                color: mat.get-color-from-palette($accent, 'default');

                margin-left: 8px;
            }
        }

        .sonder-form-field-standin {
            padding: 10px 20px;

            > span {
                align-items: center;
                display: flex;

                .credit-card-logo {
                    margin-left: 8px;
                }
            }

            .sonder-formlib-dark-background & {
                background: rgba(255, 255, 255, 0.2);
                color: inherit;
            }

            .sonder-formlib-light-background & {
                background: rgba(0, 0, 0, 0.02);
                color: mat.get-color-from-palette($primary, 'lighter');
            }
        }

        &.sm-focused .sm-mat-focusable {
            border-color: #d9d8d8;
        }

        &.sm-errors .sonder-form-field-content {
            border: 1px solid $color-message-error-base;
            background-color: $color-message-error-tint;
        }

        &.sm-success {
            .sonder-form-field-content {
                border-color: #00b78e;
            }
        }

        &.sm-disabled .sonder-form-field-content {
            background: white;
            border-color: #d9d8d8;
        }

        .mq-gt-sm &, &.mq-gt-sm {
            .sonder-form-field-after:not(:empty) {
                position: absolute;
                bottom: 25px; // content is 50px tall, plus 2px bottom border - come up from bottom to ignore label
                left: calc(100% + 12px);
                transform: translateY(50%);
            }
        }

        .mq-lt-md &, &.mq-lt-md {
            .sonder-form-field-after:not(:empty) {
                margin-top: 8px;
                margin-bottom: 24px;
                height: 0;
            }

            // scroll stand in fields on mobile
            .sonder-form-field-standin {
                overflow: auto;
            }
        }

        ul.sonder-form-errors {
            display: inline-block;
            text-align: right;
            @include iris-legal-mobile;

            @include iris-media(">xs") {
              @include iris-legal;
            }
            color: $color-message-error-base !important;

            padding: 0; margin: 0;
            list-style: none;

            z-index: 0;

            li {
                display: inline-block;

                &:not(:empty):not(:last-child):after {
                  content: ', ';
                  display: inline-block;
                  padding-right: 6px;
                }
            }
        }

        @include disabledFade();
    }

    .sonder-form-field-after:not(:empty) {
      aside {
          line-height: 0;
      }

      button {
        // this is because buttons in the label WILL NOT WORK, and will get weird event bubbling
        // use a <a> instead
        background-color: red !important;
      }
    }

    .sonder-form-helptext:not(:empty) {
        @include sm-font-small();
        @include disabledFade();

        text-align: right;

        &.left, .left {
            text-align: left;
        }

        a {
            color: mat.get-color-from-palette($primary, 200);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    // when inside a single field, fit into space take by other labels
    .sonder-form-field-helptext.sonder-form-helptext:not(:empty) {
        margin-top: $spacing-01;
    }

    // when inside a checkbox field, align with the label
    .sonder-checkbox-field .sonder-form-helptext:not(:empty) {
        padding-left: 48px;
        margin-top: -5px;
        margin-bottom: 0;
    }

    .sonder-form-optin {
        font-weight: $font-weight-medium;
        font-size: $font-size-02;
        line-height: $font-line-height-08;
        font-family: $font-family-proxima-nova;

        margin-top: 7px;
        margin-bottom: 14px;

        .mat-checkbox-inner-container {
            height: 18px; width: 18px;
            margin-top: 3px;
        }

        .mat-checkbox-label {
            white-space: normal;
            line-height: 24px !important;
        }

        .sonder-form-errors {
            width: 100%;
            position: relative;
            top: 1px; left: 1em;

            text-align: left;
        }
    }

    .sonder-form-field-label:not(:empty) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .sonder-form-field-label-text {
          @include iris-body-bold-mobile;

          @include iris-media(">xs") {
            @include iris-body-bold;
          }
        }
    }

    .sonder-form-field-label-required {
      font-size: 50%;
      color: $color-neutral-default;
      @include iris-body-bold-mobile;

      @include iris-media(">xs") {
        @include iris-body-bold;
      }
    }

    .sonder-form-field-pending {
        text-align: right;
        margin-left: 8px;

        display: flex;
        align-items: center;

        .mat-mdc-spinner {
            display: inline-block;
        }
    }

    .sonder-select-field {

        &-small {
            .mat-mdc-select-value {
                font-size: 14px;
                line-height: 33px;
            }
        }

    }

    .sonder-checkbox-field {
        position: relative;

        a {
          color: inherit;
        }

        &.mq-gt-sm, .mq-gt-sm & {
          .sonder-form-field-after:not(:empty) {
            position: absolute;
            top: 50%; left: calc(100% + 12px);
            transform: translateY(-50%);
          }
        }

        &.mq-lt-md, .mq-lt-md & {
          .sonder-form-field-after:not(:empty) {
            position: absolute;
            top: 6px; right: 0;
          }
        }

        .mat-checkbox {
            position: relative;
        }

        .mat-checkbox-inner-container {
            width: 27px;
            height: 27px;
            margin-right: 20px;
            margin-top: 2px;
        }

        .mat-checkbox-frame {
            .sonder-formlib-light-background &,
            .sonder-formlib-dark-background .sonder-formlib-light-background & {
                border-color: $color-brand-1-base;
                border-width: 1px;
            }

            .sonder-formlib-dark-background &,
            .sonder-formlib-light-background .sonder-formlib-dark-background & {
                border-color: white;
                border-width: 1px;
            }

            border-radius: 5px;
        }

        .mat-checkbox-background {
            margin: -1px;
            border-radius: 5px;
        }

        .mat-checkbox-label {
            white-space: normal;
            color: inherit;
        }

        @include disabledFade();
    }

    .sonder-form-radio-group {
        max-width: 100%;

        &.sm-horizontal {
            .mat-radio-group {
                flex-direction: row;

                .mat-radio-button {
                    width: 100%;
                    margin-bottom: 0;
                    margin-left: 16px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            .sonder-form-field-helptext:empty {
                display: none;
            }

            .sonder-form-field-after:not(:empty) {
                bottom: initial;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .mat-radio-group {
            display: inline-flex;
            flex-direction: column;

            .mat-radio-button {
                width: 100%;
                margin-bottom: 24px;

                .sonder-formlib-dark-background & {
                    &.mat-radio-checked {
                        .mat-radio-outer-circle { border-color: mat.get-color-from-palette($accent, 'default'); }
                        .mat-radio-inner-circle { background-color: mat.get-color-from-palette($accent, 'default'); }
                    }
                    
                    &:not(.mat-radio-checked) {
                        .mat-radio-outer-circle { border-color: white; }
                        .mat-radio-inner-circle { background-color: white; }
                    }
                }
            }
        }
        
        .mat-radio-label {
            width: 100%;

            .sonder-formlib-light-background & {
                background: #f3f3f3;
                color: mat.get-color-from-palette($primary, 'default');
            }

            .sonder-formlib-dark-background & {
                transition: background 300ms ease;

                background: mat.get-color-from-palette($primary, 'default');
                color: #f3f3f3;

                &:hover {
                    background: transparentize(mat.get-color-from-palette($primary, 'darker'), 0.5);
                }
            }

            box-sizing: border-box;
            padding: 16px 10px 16px 20px;

            border-radius: 5px;
            align-items: baseline;

            .mat-radio-images & {
                align-items: center;
            }

            .mat-radio-container {
                top: 5px; // to match line height given align-items baseline
            }

            .mat-radio-label-content {
                padding-left: 30px;
                white-space: normal;
                color: inherit;
            }
        }
    }

    .sonder-option-disc-message {
        @include sm-font-label();
        letter-spacing: 1.3px;
    }

    .sonder-form-checkbox-multi {
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        margin-top: 22px;

        .mat-checkbox {
            display: inline-flex;
            width: 100%;
            margin-bottom: 24px;
            font: 600 16px/16px 'proxima-nova';
            &:last-child {
                margin-bottom: 16px;
            }
            box-sizing: border-box;
            border-radius: 5px;


            .sonder-formlib-light-background & {
                background: mat.get-color-from-palette($palette-sondermind-grey, 600);
                color: mat.get-color-from-palette($primary, 'default');
            }

            .sonder-formlib-dark-background & {
                transition: background 300ms ease;

                background: mat.get-color-from-palette($primary, 'default');
                color: mat.get-color-from-palette($palette-sondermind-grey, 600);

                &:hover {
                    background: transparentize(mat.get-color-from-palette($primary, 'darker'), 0.5);
                }
            }
            .mat-checkbox-layout {
                width: 100%;
                padding: 16px 20px;

                .mat-checkbox-inner-container {
                    margin: 0px;
                    align-self: flex-start;
                    top: 5px;
                }
            }
            .mat-checkbox-label {
                padding-left: 8px;
                white-space: normal;
            }
        }

        .sonder-form-field-label-text {
            max-width: 100%;
        }
    }

}

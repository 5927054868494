@use '@angular/material' as mat;

$sm-teal: (
    50:  #b4ebde,
    100: #81dec8,
    200: #50e3c2, // light
    300: #44d0b1, // default
    400: #00c4a0,
    500: #00b690,
    600: #00a882, // dark
    700: #009772,
    800: #008663,
    900: #006847,

    contrast: (
        50:  white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$sm-gray-blue: (
    50:  #d3f2ff,
    100: #b1dae8,
    200: #8fbecf,
    300: #6ba2b6,
    400: #4f8ea3, // light
    500: #2f7a91,
    600: #226b7f,
    700: #12576a,
    800: #004455, // default
    900: #002532, // dark

    contrast: (
        50:  rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
 );

$sm-orange: (
    50:  #fcc5b1,
    100: #ffb499, // light
    200: #f99f7e, // default
    300: #f47a4c,
    400: #ef5d24, // dark
    500: #ea4100,
    600: #e03b00,
    700: #d33400,
    800: #c52d00,
    900: #ad1e00,

    contrast: (
        50:  rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$sm-red: mat.$red-palette;

$sm-app-nav:     mat.define-palette($sm-teal, 300, 200, 600);
$sm-app-primary: mat.define-palette($sm-gray-blue, 800, 400, 900);
$sm-app-accent:  mat.define-palette($sm-orange, 200, 100, 400);
$sm-app-warn:    mat.define-palette($sm-red, 500, 100, 700);

$color-config-sm-nav: (
  primary: $sm-app-nav,
  accent: $sm-app-accent,
  warn: $sm-app-warn
);

$color-config-sm-app: (
  primary: $sm-app-primary,
  accent: $sm-app-accent,
  warn: $sm-app-warn
);

$color-config-sm-dark: (
  primary: $sm-app-primary,
  accent: $sm-app-accent,
  warn: $sm-app-warn
);

$sm-nav-theme: mat.define-light-theme((
  color: $color-config-sm-nav,
  typography: mat.define-typography-config(),
  density: 0
));

$typography-config-sm-body: mat.define-legacy-typography-config(
  $headline:      mat.define-typography-level(50px, 60px, 300, 'museo-slab', $letter-spacing: 1.7px),
  $title:         mat.define-typography-level(20px, 32px, 500, 'museo-slab'),

  $font-family: 'proxima-nova',
  $subheading-2:  mat.define-typography-level(16px, 18px, 400),
  $subheading-1:  mat.define-typography-level(15px, 24px, 400),
  $body-2:        mat.define-typography-level(18px, 32px, 700),
  $body-1:        mat.define-typography-level(18px, 32px, 500),
  $caption:       mat.define-typography-level(14px, 18px, 700),
  $button:        mat.define-typography-level(14px, 14px, 700, 'proxima-nova', $letter-spacing: 4px),

  // Line-height must be unit-less fraction of the font-size.
  $input:         mat.define-typography-level(18px, 1.7, 500)
);

@mixin mat-overrides-theme() {

    .mat-button:not(.mat-calendar-period-button):not(.skip-formlib-override):not(.sondermind-button),
    .mat-flat-button:not(.mat-calendar-period-button):not(.skip-formlib-override):not(.sondermind-button),
    .mat-stroked-button:not(.mat-calendar-period-button):not(.skip-formlib-override):not(.sondermind-button) {
        letter-spacing: 4px;
        text-transform: uppercase;

        border-radius: 5px;

        .mat-datepicker-toggle & {
            padding: unset;
        }

        .mat-simple-snackbar-action & {
            padding: 0;
            margin-top: 3px;
            line-height: 24px;
        }

        & > .mat-button-wrapper > .mat-icon {
            position: relative;
            top: -2px;
        }
    }
    .mat-mdc-snack-bar-container {
        background: #323232 !important;
        border-radius: 4px !important;
        font: 400 0.875rem/1rem 'proxima-nova';
        color: #ffffffb3 !important;
        box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
    }

    .mat-mdc-button {
        font-weight: 600 !important;
        color: #40c4ff !important;
    }

    .mat-stroked-button {
        border-width: 2px;
    }
}

@mixin sm-font-medium() {
    font: 500 16px/24px 'proxima-nova';
}

@mixin sm-font-paragraph() {
    font: 400 16px/20px 'proxima-nova';
}

@mixin sm-font-paragraph-mobile() {
    font: 400 14px/16px 'proxima-nova';
}

@mixin sm-font-label() {
    font: 700 14px/18px 'proxima-nova';
    text-transform: uppercase;
    letter-spacing: 3px;
}

@mixin sm-font-legend() {
    font: 700 14px/18px 'proxima-nova';
    letter-spacing: 1.5px;
}

@mixin sm-font-legend-tiny() {
    font: 700 12px/18px 'proxima-nova';
    text-transform: uppercase;
    letter-spacing: 3.5px;
}

@mixin sm-font-cta-body() {
    font: 300 24px/35px 'museo-slab';
    letter-spacing: 0.7px;
}

@import '1-tokens/-index'; // design tokens
@import '2-tools/_tools.helpers'; // helper functions & mixins
@import '2-tools/_tools.breakpoints'; // breakpoint specific functions & mixins

// Variable $-cdk-overlay represents the default layer of overlays from the Angular CDK.
// Certain overlays need to be above or below each other, which the $-above-cdk-overlay variables can be used to achieve.
// From Angular CDK:
//    $overlay-z-index: 1000 !default;
//    https://github.com/angular/components/blob/12.2.13/src/cdk/overlay/_index.scss
$-above-cdk-overlay-2: 1400;
$-above-cdk-overlay-1: 1200;
$-cdk-overlay: 1000;
$-above-navigation: 800;
$-navigation: 600;
$-below-navigation: 400;

// The standard overlay layer covers nav on Desktop, but will be covered by nav on mobile.
// e.g. sonder-modal, VideoSessionOverlay (sidebar)
@mixin standard-overlay() {
  @include iris-media(">1024px") {
    z-index: $-above-navigation;
  }

  @include iris-media("<=1024px") {
    z-index: $-below-navigation;
  }
}

/*
  Use this mixin to ensure an overlay is above another overlay.
  Pass in 1 or 2 to specify the layer you want to be above the default cdk-overlay.
*/
@mixin above-overlay($layer: 1) {
  @if $layer == 1 {
    z-index: $-above-cdk-overlay-1;
  } @else if $layer == 2 {
    z-index: $-above-cdk-overlay-2;
  } @else {
    @error "Invalid layer value. Must be 1 or 2.";
  }
}

// Gives access to the $-cdk-overlay variable if necessary
@mixin cdk-override() {
  z-index: $-cdk-overlay;
}

// The fullscreen overlay layer will always cover nav - even on mobile.
// e.g. VideoSessionOverlay (compact, fullscreen)
@mixin fullscreen-overlay() {
  z-index: $-above-navigation;
}

@mixin navigation() {
  z-index: $-navigation;
}

@mixin sticky-headers() {
  z-index: $-below-navigation - 1;
}

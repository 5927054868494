@use 'sass:math';
@use '@angular/material' as mat;

@import 'mixins';

@mixin sondermind-system-card-layout($theme, $font) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);

  $max-width: 1124px;
  $min-width: 768px;

  $padding:   24px;
  $gutter:    12px;
  $radius:    1px;

  /*

  .sondermind-card-layout maxspan=3 wrap=false
    .sondermind-card-layout maxspan=2 wrap=true
      .sm-cl-item width=1
      .sm-cl-item width=1
      .sm-cl-item width=2
    .sm-cl-item width=1

  sondermind-card-layout

  .sm-cl-layout
    data-collapsed - full width, column stacked
    data-separated - when collapsed, show separation border
    data-span      - max child span, + span for when embedded
    data-valign    - vertical align for layout items (gravity is internal valign)
    data-wrap      - whether to wrap flex children

  .sm-cl-item
    data-span          - span to take of the parent
    data-bleed         - should the container bleed over to siblings?
    data-content-bleed - should the content bleed over the padding?
    data-gravity       - vertical align for internal content
    data-align         - horizontal align for internal content
    data-highlight     - have a special background
    data-undecorated   - don't display backgrounds, fix padding so it still fits
    data-mobile        - are we in mobile?

  */

  .sondermind-card-layout {
    // create z-index context for the box-shadows
    position: relative;
    z-index: 0;

    @include sondermind-card-layout-card-container-mixin($max-width, $min-width, $background, $padding, $gutter, $level: 1);

    .sm-cl-item {
      @include sondermind-card-layout-card-item-mixin($background, $radius, $padding, $gutter);
      @include sondermind-card-layout-card-content-mixin($font, $primary);
    }

    .sm-cl-separator {
      flex: 1 1 auto;
      display: block;
    }
  }
}

@mixin sondermind-card-layout-card-container-mixin($max-width, $min-width, $background, $padding, $gutter, $level) {

  display: flex;
  flex-direction: row;

  &[data-valign="center"] {
    align-items: center;
  }

  &[data-wrap=''], &[data-wrap='true'] {
    flex-wrap: wrap;
  }

  > .sm-cl-item, > .sm-cl-layout {
    &[data-content-bleed~="top"] {
      padding-top: 0 !important;

      > header, .undo-content-bleed {
        margin-top: $padding !important;
      }
    }

    .content-bleed-top {
        margin-top: -$padding;

        .undo-content-bleed {
            margin-top: $padding !important;
        }
    }

    &[data-content-bleed~="bottom"] {
      padding-bottom: 0 !important;

      :last-child.undo-content-bleed {
        margin-bottom: $padding !important;
      }
    }

    .content-bleed-bottom {
        margin-bottom: -$padding;

        .undo-content-bleed {
            margin-bottom: $padding !important;
        }
    }
  }

  &[data-maxspan='2'] {
    > .sm-cl-item {
      &[data-span='1'] { @include sondermind-card-layout-card-layout-mixin(1, 2, $max-width, $min-width, $background, $padding, $gutter); }
      &[data-span='2'] { @include sondermind-card-layout-card-layout-mixin(2, 2, $max-width, $min-width, $background, $padding, $gutter); }
    }

    > .sm-cl-layout {
      &[data-span='1'] { @include sondermind-card-layout-card-layout-mixin(1, 2, $max-width, $min-width, $background, $padding, $gutter, true, $level); }
      &[data-span='2'] { @include sondermind-card-layout-card-layout-mixin(2, 2, $max-width, $min-width, $background, $padding, $gutter, true, $level); }
    }
  }

  &[data-maxspan='3'] {
    > .sm-cl-item {
      &[data-span='1'] { @include sondermind-card-layout-card-layout-mixin(1, 3, $max-width, $min-width, $background, $padding, $gutter); }
      &[data-span='2'] { @include sondermind-card-layout-card-layout-mixin(2, 3, $max-width, $min-width, $background, $padding, $gutter); }
      &[data-span='3'] { @include sondermind-card-layout-card-layout-mixin(3, 3, $max-width, $min-width, $background, $padding, $gutter); }
    }

    > .sm-cl-layout {
      &[data-span='1'] { @include sondermind-card-layout-card-layout-mixin(1, 3, $max-width, $min-width, $background, $padding, $gutter, true, $level); }
      &[data-span='2'] { @include sondermind-card-layout-card-layout-mixin(2, 3, $max-width, $min-width, $background, $padding, $gutter, true, $level); }
      &[data-span='3'] { @include sondermind-card-layout-card-layout-mixin(3, 3, $max-width, $min-width, $background, $padding, $gutter, true, $level); }
    }
  }

  // collapsed layout

  &[data-collapsed="true"], &[data-collapsed=""] {
    flex-direction: column !important;

    // separate adjacent collapsed card layouts

    &[data-separated="true"], &[data-separated=""] {
      .sm-cl-item {
        &:not([data-undecorated]), &[data-undecorated="false"] {
          border-bottom: 16px solid mat.get-color-from-palette($background, 'card-layout-item-separator');
        }
      }
    }

    // remove separation when non-separated

    &[data-separated="false"], &:not([data-separated]) {
      .sm-cl-item {
        padding-bottom: 0 !important;
      }
    }

    // fix item layout in collapsed state

    > .sm-cl-item, > .sm-cl-layout {
      flex: 1 1 auto !important;
      min-width: auto !important;
      max-width: none !important;
    }

    // layouts don't get special handling for margin/padding,
    // they already have 0

    > .sm-cl-item {
      margin: 0 !important;
      padding: $padding !important;

      &[data-content-bleed~="right"] {
        padding-right: 0 !important;
        > header {
          padding-right: $padding !important;
        }
      }

      .content-bleed-right {
          display: block;
          width: calc(100% + #{$padding});
          margin-right: -$padding;

          .undo-content-bleed {
              margin-right: $padding;
          }
      }

      &[data-content-bleed~="left"] {
        padding-left: 0 !important;
        > header {
          padding-left: $padding !important;
        }
      }

      .content-bleed-left {
          display: block;
          width: calc(100% + #{$padding});
          margin-left: -$padding;

          .undo-content-bleed {
              margin-left: $padding;
          }
      }

      .content-bleed-left.content-bleed-right {
          width: calc(100% + #{$padding * 2});
      }

      &[data-content-bleed~="top"] {
        padding-top: 0 !important;
        > header {
          padding-top: $padding !important;
        }
      }

      .content-bleed-top {
          margin-top: -$padding;
          .undo-content-bleed {
              margin-top: $padding;
          }
      }

      &[data-content-bleed~="bottom"] {
        padding-bottom: 0 !important;
      }

      .content-bleed-bottom {
          margin-bottom: -$padding;
          .undo-content-bleed {
              margin-bottom: $padding;
          }
      }

      // remove shadows from items when stacked
      &:before {
        display: none;
      }
    }
  }
}

@mixin sondermind-card-layout-card-layout-mixin($cols, $of, $layout-max-width, $layout-min-width, $background, $padding, $gutter, $container: false, $level: 0) {

  /*

       |<------------------- max width ------------------>|

  |    +----+----------+----|    |    |----+---------+----+    |    +----+----------+----|    |
  | $g | $p |          | $p | $g | $g | $p |         | $p | $g | $g | $p |          | $p | $g |
  |    +----+----------+----|    |    |----+---------+----+    |    +----+----------+----|    |

            |<-------->|
               span width (content box box-sizing)

  |    +----+----------+----+----|----+----+---------+----+    |    +----+----------+----|    |
  | $g | $p |          . $p . $g . $g . $p .         | $p | $g | $g | $p |          | $p | $g |
  |    +----+----------+----+----|----+----+---------+----+    |    +----+----------+----|    |

            |<-------------------------------------->|
               span width * cols + subsummed gutter & padding

  */

  $span-max-width: math.div($layout-max-width, $of) - (($gutter + $padding) * 2);
  $span-min-width: math.div($layout-min-width, $of) - (($gutter + $padding) * 2);

  // we're subsuming some gutters

  $gutter-count: $cols - 1;
  $gutter-width: $gutter-count * (($gutter + $padding) * 2);

  // if we're a container, we don't get gutters or margin, and we expand to fill the space

  $extra-width: 0;

  @if ($container) {
    $extra-width: ($gutter * 2) + ($padding * 2);
  }

  // width is single span * cols + gutters subsummed

  $max-width: ($cols * $span-max-width) + $gutter-width + $extra-width;
  $min-width: ($cols * $span-min-width) + $gutter-width + $extra-width;

  @if ($level > 0) {
    @include sondermind-card-layout-card-container-mixin($max-width, $min-width, $background, $padding, $gutter, $level - 1);
  }

  // flex configuration

  flex: $cols $cols $min-width;
  max-width: $max-width;
  min-width: $min-width;

  @if (not $container) {
    margin: $gutter;
    padding: $padding;

    // undecorated removes gutters only
    &[data-undecorated="true"], &[data-undecorated=""] {
      margin: 0;

      min-width: $min-width + $gutter * 2;
      max-width: $max-width + $gutter * 2;
    }
  }

  // normal layout bleeds
  // don't interact with each other, don't need the 'both' case special

  &[data-bleed~="left"] {
    margin-left: 0;
    padding-left: $padding + $gutter;
  }

  &[data-bleed~="right"] {
    margin-right: 0;
    padding-right: $padding + $gutter;
  }

  // content bleeds
  // have to fix layout bleed in this case too, to fix max-width
  // set max, min and flex-basis together, so things grow at the right rate

  &[data-content-bleed~="right"] {
    padding-right: 0;
    max-width: $max-width + $padding;
    min-width: $min-width + $padding;
    flex-basis: $min-width + $padding;

    > header, .undo-content-bleed {
      padding-right: $padding;
    }

    &[data-bleed~="right"] {
      max-width: $max-width + $padding + $gutter;
      min-width: $min-width + $padding + $gutter;
      flex-basis: $min-width + $padding + $gutter;

      > header {
        padding-right: $padding + $gutter;
      }
    }
  }

  &[data-content-bleed~="left"] {
    padding-left: 0;
    max-width: $max-width + $padding;
    min-width: $min-width + $padding;
    flex-basis: $min-width + $padding;

    > header, .undo-content-bleed {
      padding-left: $padding;
    }

    &[data-bleed~="left"] {
      max-width: $max-width + $padding + $gutter;
      min-width: $min-width + $padding + $gutter;
      flex-basis: $min-width + $padding + $gutter;

      > header {
        padding-left: $padding + $gutter;
      }
    }
  }

  // content bleed in both directions requires support
  // to update the max width with the otherwise missing gutters

  &[data-content-bleed~="right"][data-content-bleed~="left"] {
    max-width: $max-width + $padding * 2;
    min-width: $min-width + $padding * 2;
    flex-basis: $min-width + $padding * 2;

    &[data-bleed~="left"] {
      max-width: $max-width + $padding * 2 + $gutter;
      min-width: $min-width + $padding * 2 + $gutter;
      flex-basis: $min-width + $padding * 2 + $gutter;
    }

    &[data-bleed~="right"] {
      max-width: $max-width + $padding * 2 + $gutter;
      min-width: $min-width + $padding * 2 + $gutter;
      flex-basis: $min-width + $padding * 2 + $gutter;
    }

    &[data-bleed~="right"][data-bleed~="left"] {
      max-width: $max-width + $padding * 2 + $gutter * 2;
      min-width: $min-width + $padding * 2 + $gutter * 2;
      flex-basis: $min-width + $padding * 2 + $gutter * 2;
    }
  }

}

@mixin sondermind-card-layout-card-item-mixin($background, $radius, $padding, $gutter) {

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  // gravity = how to align internal contents vertically

  &, &[data-gravity="top"] {
    justify-content: flex-start;
  }

  &[data-gravity="middle"] {
    justify-content: center;
  }

  &[data-gravity="bottom"] {
    justify-content: flex-end;
  }

  // align = how to align internal contents horizontally

  &, &[data-align="left"] {
    align-items: flex-start;
  }

  &[data-align="center"] {
    align-items: center;
  }

  &[data-align="right"] {
    align-items: flex-end;
  }

  &[data-align="stretch"] {
    align-items: stretch;
  }

  &[data-undecorated="false"], &:not([data-undecorated]) {

    // background color

    background-color: mat.get-color-from-palette($background, 'card-layout-item');

    &[data-highlight="true"], &[data-highlight=""] {
      background-color: mat.get-color-from-palette($background, 'card-layout-item-highlight');
    }

    &[data-highlight="dark"] {
      background-color: mat.get-color-from-palette($background, 'card-layout-item-highlight-dark');
    }

    // shadow
    // shadow goes into :before with z-index -1 so that the shadows don't overlap adjacent spans

    &:before {
      content: "";
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;

      z-index: -1;
      box-shadow:
        0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 4px 5px 0 rgba(0, 0, 0, 0.14);

      box-shadow:
        0 1px 3px 0 rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.12),
        0 0 2px 0 rgba(0, 0, 0, 0.14);
    }

    // radius
    // apply to the shadow :before, so everything maps up

    &, &:before {
      border-radius: $radius;
    }

    &[data-bleed~="left"] {
      &, &:before {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

}

@mixin sondermind-card-layout-card-content-mixin($font, $primary) {
  position: relative;

  // mobile overrides
  &[data-mobile="true"], &[data-mobile=""] {
    > header {
      @include sondermind-typography-level-to-styles($font, card-layout-header-mobile);

      > .mat-icon, > .sondermind-icon-loader {
        font-size: 26px;
        margin-right: 18px;
      }
    }
  }

  &[data-mobile="false"], &:not([data-mobile]) {
    > header {
      @include sondermind-typography-level-to-styles($font, card-layout-header);
      flex-wrap: wrap;
    }
  }

  > header {
    color: mat.get-color-from-palette($primary, 600);
    user-select: none;

    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    // icons first
    > .mat-icon, > .sondermind-icon-loader {
      flex: 0 0 24px;
      order: 0;
    }

    // undo inline icons fix for align-items: center;
    > .mat-icon, > .sondermind-icon-loader > .mat-icon {
      top: -1px;
      margin-right: 4px;
    }

    // insert a before element to fill space before the button (order: 2)
    &:before {
      content: '';
      display: block;
      flex: 1 1 auto;
      order: 2;
    }

    // put the button after the spacer
    > .mat-button, > a {
      flex: 0 0 auto;
      order: 3;
    }

    > .mat-mdc-select {
      @include sondermind-typography-level-to-styles($font, card-layout-header-control);

      flex: 1 1 40%;
      order: 3;

      text-align: right;

      .mat-mdc-select-trigger {
        height: auto;
      }

      .mat-mdc-select-value {
        padding-right: 8px;
      }

    }

    // flex basis 100% plus wrap means this endup up on it's own line
    > small {
      @include sondermind-typography-level-to-styles($font, card-layout-header-small);
      flex: 1 1 100%;
      order: 4;
    }
  }

}
